<template>
  <h2 class="mt-4">Lassen Sie uns starten!</h2>
  <div class="row my-3 text-center">
    Um zu überprüfen, ob Sie für die Teilnahme in Frage kommen, werden wir Ihnen
    zu Beginn 3 kurze Fragen stellen.
  </div>
  <div class="row my-4 justify-content-center">
    <font-awesome-icon icon="fa-clock" class="mb-2" style="font-size: 2em" />
    Dauer: 3 Minuten
  </div>
</template>

<script setup></script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 50px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
